import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import headerBackground from '../../assets/163137262_l.webp'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundImage: `url(${headerBackground})`,
      width: '100%',
      backgroundPosition: 'top center',
      backgroundSize: '100%',
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('lg')]: {
        backgroundSize: '1900px',
      },
    },
    container: {
      width: '100%',
      margin: '0px auto',
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
    },
  }),
);
