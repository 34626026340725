import { createTheme } from "@mui/material";

export const theme = createTheme({
  typography: {
    allVariants: {
      letterSpacing: '-.3px'
    },
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    button: {
      textTransform: 'none'
    },
    h4: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    h5: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    h6: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    }
  }
});