import { CssBaseline, StyledEngineProvider } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./features/Home";
import ScrollToTop from "./utils/scrollToTop";
import privacy_EN from "./assets/Privacy_Policy_GW_PR0_EN.pdf";
import terms_EN from "./assets/Terms_of_Use_GW_PRO_EN.pdf";
import privacy_FR from "./assets/Politique_de_Confidentialité_GW_PR0_FR.pdf";
import terms_FR from "./assets/Terms_of_Use_GW_PRO_FR.pdf";
import PdfView from "./features/PdfView";

function App() {
  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy_EN" element={<PdfView filepath={privacy_EN}/>}/>
          <Route path="/privacy_fr" element={<PdfView filepath={privacy_FR}/>}/>
          <Route path="/terms_en" element={<PdfView filepath={terms_EN}/>}/>
          <Route path="/terms_fr" element={<PdfView filepath={terms_FR}/>}/>
        </Routes>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
