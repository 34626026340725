import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    padding: '20px',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 'none',
    },
  },
  appBar: {
    backgroundColor: 'transparent',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexGrow: 'none',
      '&.MuiAppBar-positionFixed': {
        right: 'auto',
      },
      '&.MuiAppBar-root': {
        width: '100%',
      },
    },
  },
  appBarWhite: {
    backgroundColor: 'white',
    display: 'flex',
    // transition: '0.1s ease-out',
  },
  toolbar: {
    maxWidth: '1680px',
    margin: 'auto',
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '0',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0',
    },
  },
  logo: {
    maxHeight: '40px',
    [theme.breakpoints.down('md')]: {
      maxHeight: '18px'
    },
  },
  nav_menu: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  nav_btn: {
    color: 'black',
    margin: '0px 10px'
  },
  nav_btn_white: {
    color: 'white',
    margin: '0px 10px'
  },
  nav_download: {
    fontWeight: 'bold',
    border: '1px solid',
    borderRadius: '20px',
    padding: '6px 40px',
  },
  download_hover: {
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  },
  download_hover_white: {
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  dropdownMenu: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
    },
  },
  underlined_header_btn: {
    position: 'relative',
    borderBottom: '1px solid transparent',
    transition: 'all 0.3s ease-out',
    '&:hover': {
      borderBottom: '2px solid'
    },
  },
  popOverRoot: {
    pointerEvents: "none"
  },
  customWidth: {
    '& div': {
        width: '120px',
    }
  }
}))
