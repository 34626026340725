import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useWindowSize } from "../../hooks/useWindowSize";

interface PropsI {
  filepath: string;
}

const PdfView = ({ filepath }: PropsI) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { width, height } = useWindowSize();

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <div style={{ width: "100%" }}>
      <div style={{ maxWidth: "1920px", margin: "auto" }}>
        <Document file={filepath} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              width={width}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PdfView;
