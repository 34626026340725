import { useEffect } from 'react';

export default function ScrollToTop() {
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, []);

  return null;
}
