import { Box } from "@mui/material";
import React, { Suspense, lazy } from "react";
// import Footer from "../../components/Footer";
import Header from "../../components/Headers";
import Loading from "../../components/Loading";
// import Featuring from "../Featuring";
// import HeaderBanner from "../HeaderBanner";
// import HowItWorks from "../HowItWorks";
// import Why from "../Why";
import { useStyles } from "./style";

const Home = () => {
  const classes = useStyles();
  const HeaderBanner = lazy(() => import("../HeaderBanner"));
  const Featuring = lazy(() => import("../Featuring"));
  const Footer = lazy(() => import("../../components/Footer"));
  const HowItWorks = lazy(() => import("../HowItWorks"));
  const Why = lazy(() => import("../Why"));
  return (
    <Suspense fallback={<Loading />}>
      <div>
        <Header />
        <Box className={classes.box}>
          <Box className={classes.container}>
            <Suspense fallback={<div />}>
              <HeaderBanner />
            </Suspense>
            <Suspense fallback={<div />}>
              <Featuring />
            </Suspense>
            <Suspense fallback={<div />}>
              <HowItWorks />
            </Suspense>
            <Suspense fallback={<div />}>
              <Why />
            </Suspense>
          </Box>
        </Box>
        <Footer />
      </div>
    </Suspense>
  );
};

export default Home;
