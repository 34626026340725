import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import logo from "../../assets/Original_GW_PRO.webp";
import React from "react";
import { useStyles } from "./style";
import MenuIcon from "@mui/icons-material/Menu";
import { scrollToSection } from "../../utils/scrollToSection";
import LanguageIcon from "@mui/icons-material/Language";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { useTranslation } from "react-i18next";
import { handleDownloadLinkIos, handleDownloadLinkAndroid } from "../../utils/handleDownloadLink";
import Collapse from '@mui/material/Collapse';



const Header = () => {
  const { t, i18n } = useTranslation();
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const textDisplay = [
    { text: "Why_GuardWatch_PRO?", class: "why" },
    { text: "Features", class: "featuring" },
    { text: "How_it_works", class: "how" },
  ];
  const classes = useStyles();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const scrollTop = React.useCallback(() => {
    document.body.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const toggleDrawer = (open: boolean) => (event: any) => {
    setOpenDrawer(open);
  };
  
    let currentlyHovering = false;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event: any) => {
        if (anchorEl !== event.currentTarget) {
          setAnchorEl(event.currentTarget);
        }
      }
      const handleHover = () => {
        currentlyHovering = true;
      }
      const handleClose = () => {
        setAnchorEl(null);
      }
      const handleCloseHover = () => {
        currentlyHovering = false;
        setTimeout(() => {
          if (!currentlyHovering) {
            handleClose();
          }
        }, 50);
      }
    const [open, setOpen] = React.useState(true);

  const Popup = () => {
    return (
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <React.Fragment>
            <IconButton {...bindTrigger(popupState)}>
              <LanguageIcon
                style={{
                  fontSize: 30,
                  color: trigger ? "black" : "white",
                }}
              />
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  i18n.changeLanguage("en");
                  popupState.close();
                }}
              >
                English
              </MenuItem>
              <MenuItem
                onClick={() => {
                  i18n.changeLanguage("fr");
                  popupState.close();
                }}
              >
                Français
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    );
  };

  return (
    <div>
      <AppBar
        className={trigger ? classes.appBarWhite : classes.appBar}
        elevation={trigger ? 3 : 0}
      >
        <Toolbar>
          <Box className={classes.toolbar} flexGrow={1}>
            <Button
              onClick={scrollTop}
              disableTouchRipple
              startIcon={<img className={classes.logo} src={logo} alt="logo" />}
            />
            <Box className={classes.nav_menu}>
              <Popup />
              {textDisplay.map((e, i) => (
                <Button
                  variant="text"
                  key={i}
                  onClick={() => scrollToSection(e.class)}
                  className={trigger ? classes.nav_btn : classes.nav_btn_white}
                >
                  <Typography className={classes.underlined_header_btn}>
                    {t(`translations.${e.text}`)}
                  </Typography>
                </Button>
              ))}
                <Button
                  variant="text"
                  className={`${
                    trigger ? classes.nav_btn : classes.nav_btn_white
                  } ${classes.nav_download} ${
                    trigger
                      ? classes.download_hover
                      : classes.download_hover_white
                  }`}
                  aria-owns={anchorEl ? "simple-menu" : undefined}
                  aria-haspopup="true"
                  onMouseOver={handleClick}
                  onMouseLeave={handleCloseHover}
                >
                  {t("translations.Download")}
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  MenuListProps={{
                    onMouseEnter: handleHover,
                    onMouseLeave: handleCloseHover,
                    style: { pointerEvents: "auto" }
                    
                  }}
                  // className={classes.customWidth}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  PopoverClasses={{
                    root: classes.popOverRoot
                  }}
                >
                  <MenuItem><a href={handleDownloadLinkAndroid()} target="_blank" rel="noreferrer">Android</a></MenuItem>
                  <MenuItem ><a href={handleDownloadLinkIos()} target="_blank" rel="noreferrer">iOS</a></MenuItem>
                </Menu>
            </Box>
            <Box className={classes.dropdownMenu}>
              <Popup />
              <IconButton size="medium" onClick={toggleDrawer(true)}>
                <MenuIcon
                  style={{ fontSize: 35, color: trigger ? "black" : "white" }}
                />
              </IconButton>
              <Drawer
                anchor={"top"}
                open={openDrawer}
                onClose={toggleDrawer(false)}
              >
                <Box onClick={toggleDrawer(false)}>
                  {textDisplay.map((e, i) => (
                    <List key={i}>
                      <ListItem onClick={() => scrollToSection(e.class)}>
                        <ListItemText primary={t(`translations.${e.text}`)} />
                      </ListItem>
                    </List>
                  ))}
                  <List>
                    <ListItem>
                      {/* <a target="_blank" href={handleDownloadLink()} rel="noreferrer">
                        <ListItemText primary={t("translations.Download")} />
                      </a> */}
                      <ListItemText primary={t("translations.Download")} />
                    </ListItem>
                    <Collapse in={open} timeout="auto">
                      <List component="div" disablePadding>
                      <a href={handleDownloadLinkAndroid()} target="_blank" rel="noreferrer">
                        <ListItemButton sx={{ pl: 4 }}>
                          <MenuItem>Android</MenuItem>
                        </ListItemButton>
                      </a>
                      <a href={handleDownloadLinkIos()} target="_blank" rel="noreferrer">
                        <ListItemButton sx={{ pl: 4 }}>
                          <MenuItem>iOS</MenuItem>
                        </ListItemButton>
                      </a>
                      </List>
                    </Collapse>
                  </List>
                </Box>
              </Drawer>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
